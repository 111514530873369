@import "https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css";
//@import "https://cdn.jsdelivr.net/npm/reset-css@5.0.1/reset.min.css";
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Nunito+Sans:wght@400&display=swap');

// layout first
// then fonts
// solve colors last
$text-on-primary-color: #fff;
$text-on-light-color: rgb(15, 15, 15);
$primary-color-dark: rgb(0, 94, 138);
$primary-color-light: rgb(0, 182, 214);
$background-light: #fff;
$background-mid: #eee;
$background-dark: rgb(30, 50, 60);

@mixin mediaLg {
  @media (min-width: 640px) {
    @content;
  }
}

@mixin mediaXl {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin separator {
  content: "\25AB";
  margin: 0 0.5rem;
}


* {
  box-sizing: border-box;
}

body {  
  font-family: "Nunito Sans", sans-serif;
  //background-color: black;
  line-height: 1.42857;
  overflow-x: hidden;
}

header, main, footer {
  display: block;
}

header {
  display:flex;
  flex-direction: column;
  align-items: center;
  padding: 7rem 0;
  background-size: cover;  
  background-color: rgb(0, 255, 21);
  background: linear-gradient(170deg,rgb(0, 182, 70),$primary-color-light);
  color: $text-on-primary-color;  

  .header-heading {
    font-family: 'Abril Fatface', cursive;
    font-size: 7.5rem;
    margin:0;
    
    @include mediaLg{
      font-size: 9.5rem;
    }
  }

  h2 {
    margin:0;
    font-size: .7rem;
    letter-spacing: 0.03rem;
    word-spacing: 0.2rem;
    text-transform: uppercase;
    font-weight: 100;

    @include mediaLg{
      font-size: 1rem;
    }
  }

  .sub-heading-container {
    display: flex;    

    h2:not(:last-child):after {
      @include separator;
    }
  }

  .secondary-heading {    
    margin-bottom: 1rem;        
  }
  

  .header-button {
    display: inline-block;
    padding: 1rem 1.2rem;
    margin-bottom: 0.2rem;
    text-decoration: none;
    color: inherit;
    font-size: 1.7rem;
    font-weight: 200;
    border: 2px solid;
    border-radius: 0.3rem;
    transition: all 0.5s;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    background-color: inherit;

    &:hover {      
      background-color: transparentize(black,0.8);
    }
  }

  .header-button-desc {
    display: flex;
    flex-flow: column;    
    font-size: 0.8rem;    
  }
}

section {
  &.section-padding {
    padding: 5rem 1rem;

    @include mediaLg{
      padding: 5rem 3rem;
    }
  }

  &.bg-mid {
    background-color: $background-mid;
  }

  color: lighten($text-on-light-color,30);

  strong {
   color: $text-on-light-color;    
  }
  
  .section-heading {
    margin:0;
    font-family: 'Abril Fatface', cursive;
    font-size: 3rem;
    color: $text-on-light-color;
    
    &::after {
      content:"";
      display: block;
      width: 60px;
      height: 3px;
      background-color: $primary-color-light;
      margin: 1rem auto 2rem auto;
    }
  
    &.center {
      text-align: center;
    }
  
    &.left {
      &::after {
        margin-left:0;
      }
    }
  }
}

.card-container {
  display: flex;
  flex-wrap: wrap;  
  justify-content: center;
  //max-width: 45rem;

  /*** grid solution ***/
  //max-width: 45rem;
  //display: grid;
  //grid-template-columns: repeat( auto-fit, minmax(20rem,1fr));
  //justify-items: center;
  /*** grid solution end***/

  margin: 0 auto;
}

.card {
  padding: 1.5rem 1rem;

  /*** grid solution ***/
  //width: 20rem;
  /*** grid solution end***/

  min-width: 20rem; // minimum width to be shrinked 
  width: 20rem; // different widths grow same way
  flex-grow: 1; // let them grow to max-width
  max-width: 22rem; // grow limit
  
    
  @include mediaLg{    
    padding: 1.5rem;
  }

  .card-heading {
    text-align: center;
    font-family: 'Abril Fatface', cursive;
    font-size: 2rem;
    color: $text-on-light-color;
    margin: 0 0 2rem 0;
  }

  .card-list {
    list-style-type: square;
    margin: 0;
    padding-left: 1rem;    
  }

}

.two-cols {
  display: grid;
  grid-template-columns: 1rem auto;

  @include mediaLg{
    grid-template-columns: 3rem auto;
  }

  @include mediaXl{
    grid-template-columns: 33% auto;
  }
}

.section-bg-image {
  background-color: $primary-color-light;
}

footer {
  
  background-color: darken($primary-color-dark,10);
  color:$text-on-primary-color;

  .footer-container {
    padding: 2rem 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 70rem;
    margin: 0 auto;

    @include mediaLg{    
      padding: 3rem 5rem;
    }
  }


  .footer-heading {
    margin-top: 0; 
    margin-bottom: 1rem;
  }

  address {
    font-style: normal;
  }

  .address-container, .contacts {
    padding: 1rem 1rem;
  }
  
  a {
    display: block;
    text-decoration: none;
    color: $text-on-primary-color;
  }
}

